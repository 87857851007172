import "../css/app.pcss";
import router from "./router";
import { Menu } from "./components/Menu";
import { SwupInit } from "./components/Swup";
import gsap from "gsap/all";

window.addEventListener("load", () => {
  const menu = new Menu(document.querySelector(".navigation"));
  menu.init();
  SwupInit(menu);
  router();

  gsap.to(".preloader", {
    opacity: 0,
    duration: 0.6,
    delay: 0.2,
    ease: "power2.out",
    onComplete: () => document.querySelector(".preloader")?.remove(),
  });
});
