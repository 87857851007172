const router = async (el) => {
  const page = el || document.querySelector('#content-wrapper')
  // Dynamically Load Modules
  let moduleName = 'base'
  if (page && page.dataset.module) moduleName = page.dataset.module

  const pageModule = await import('./routes/' + moduleName)
  const pageClass = new pageModule.default(page)
  pageClass.init()

  return pageClass
}

export default router