import Swup from 'swup'
import SwupJsPlugin from '@swup/js-plugin'
import SwupScrollPlugin from '@swup/scroll-plugin'
import { gsap, ScrollTrigger } from 'gsap/all'
import router from '../router'

gsap.registerPlugin(ScrollTrigger)

gsap.defaults({
  duration: 0.3,
  ease: 'power2'
})
export const SwupInit = (menu) => {
  const options = [
    {
      from: '(.*)', // meaning any
      to: '(.*)', // meaning any
      out: (next) => {
        if (document.querySelector('.menu-button').classList.contains('open')) {
          menu.closeMenu()
          document.querySelector('.menu-button').classList.remove('open')
        }
        gsap.to(document.querySelector('.content-main'), 0.5, {
          opacity: 0,
          y: -160,
          onComplete: next
        })
      },
      in: (next) => {
        router()
        gsap.set(document.querySelector('.content-main'), { y: 160 })
        gsap.to(document.querySelector('.content-main'), 0.5, {
          opacity: 1,
          y: 0,
          onComplete: () => {
            next()
            menu.setActiveGlobal()
            ScrollTrigger.refresh()
          },
          clearProps: 'all'
        })
      }
    }
  ]

  const swup = new Swup({
    plugins: [new SwupJsPlugin(options), new SwupScrollPlugin({
      doScrollingRightAway: true
    })],
    containers: ['.content-main'],
    requestHeaders: {
      "X-Requested-With": "XMLHttpRequest"
    },
    cache: true
  });
}