import gsap, { ScrollTrigger } from 'gsap/all'

gsap.registerPlugin(ScrollTrigger)

export class Menu {
  constructor(el){
    this.el = el
    this.button = this.el.querySelector('.menu-button')
    this.menu = this.el.querySelector('.menu-wrapper')
    this.subMenuItems = this.el.querySelectorAll('.menu-item.sub')
    this.primaryHeights = []
    this.openSub = null
  }

  init = () => {
    this.button.addEventListener('click', this.menuToggle)
    this.resizeHandler()
    window.addEventListener('resize', this.resizeHandler)

    this.menuHide()
    this.subMenu()
  }

  menuHide = () => {
    ScrollTrigger.create({
      trigger: document.body,
      start: '96px top',
      end: 99999,
      onUpdate: ({direction, isActive}) => {
        if(direction == 1){
          this.el.classList.add('-translate-y-full')
        }else{
          this.el.classList.remove('-translate-y-full')
        }

        if (this.button.classList.contains('open')) {
          this.closeMenu()
          this.button.classList.remove('open')
        }
      }
    })
  }

  subMenu = () => {    
    this.subMenuItems.forEach(item => {
      item.addEventListener('mouseenter', () => window.matchMedia('(min-width: 1024px)').matches && item.anim.play())
      item.addEventListener('mouseleave', () => window.matchMedia('(min-width: 1024px)').matches && item.anim.reverse())
      item.querySelector('.sub-link').addEventListener('click', (e) => {
        if(!window.matchMedia('(min-width: 1024px)').matches){
          e.preventDefault()
          item.anim.play()
        }
      })

      item.querySelector('.back').addEventListener('click', () => item.anim.reverse())
    })
  }

  menuToggle = () => {
    this.button.classList.toggle('open')

    if (this.button.classList.contains('open')) {
      this.openMenu()
    }else{
      this.closeMenu()
    }
  }

  openMenu = () => {
    gsap.to(this.menu, {
      height: 'auto',
      duration: 0.6,
      ease: 'power2.out',
      onStart: () => this.button.innerText = 'Close'
    })
  }

  closeMenu = () => {
    this.openSub && this.openSub.anim.reverse()
    gsap.to(this.menu, {
      height: 0,
      duration: 0.6,
      ease: 'power2.out',
      onStart: () => this.button.innerText = 'Menu'
    }) 
  }

  resizeHandler = () => {
    if(window.matchMedia('(min-width: 1024px)').matches){
      this.subMenuItems.forEach(item => {
        const subMenu = item.querySelector('.sub-menu')
        if(item.anim) item.anim.kill()
        gsap.set(this.el.querySelectorAll('.menu-wrapper>.menu-item'), { clearProps: 'all' })
        
        item.anim = gsap.to(subMenu, {
          height: 'auto',
          duration: 0.6,
          ease: 'power2.out',
          paused: true
        })
      })
    }else{
      this.subMenuItems.forEach(item => {
        if(item.anim) item.anim.kill()
        const subMenu = item.querySelector('.sub-menu')
        gsap.set([item, subMenu], { clearProps: 'all' })

        item.anim = gsap.timeline({ paused: true, onStart: () => this.openSub = item, onReverseComplete: () => this.openSub = null })
        item.anim.to(this.el.querySelectorAll('.menu-wrapper>.menu-item'), {
          x: '-100%',
          duration: 0.6,
          ease: 'power2.inOut',
          onStart: () => {
            subMenu.classList.remove('opacity-0', 'pointer-events-none')
          },
          onReverseComplete: () => {
            subMenu.classList.add('opacity-0', 'pointer-events-none')
          }
        })
        
        item.anim.to(this.menu, {
          height: subMenu.clientHeight,
          duration: 0.15,
          ease: 'power2.out'
        }, '<')
      })
    }
  }

  setActiveGlobal = () => {
    const path = window.location.pathname
    const currentLink = this.menu.querySelector(`a.menu-item[href*="${path}"], a.sub-menu-item[href*="${path}"]`)

    this.menu.querySelectorAll(`.menu-item`).forEach(link => {
      link.classList.remove('active', 'lg:text-silver')
    })
    
    if(currentLink && path !== '/'){
      if(currentLink.classList.contains('sub-menu-item')){
        currentLink.parentElement.parentElement.parentElement.querySelector('.sub-link').classList.add('active', 'lg:text-silver')
      }else{
        currentLink.classList.add('active', 'lg:text-silver')
      }

    }else{
      this.menu.querySelectorAll(`a.menu-item`).forEach(link => {
        link.classList.remove('active')
      })
    }
  }
}